import { isAfter } from "date-fns";
import jwtDecode from "jwt-decode";
import * as ActionType from "../actions/actionType";
import { userCategoryType } from "../constant/propertiesConstant";
import { MIME } from "../constant/mimeConstant";

export const setLanguage = (locale, dispatch) => {
  dispatch({ type: ActionType.SET_LANGUAGE, payload: locale });
};


/**
 * This function input array of object and key is object key, and return unique of that array of object
 * @param {Array} array - Array of object
 * @param {string} key - key of object that make unique
 * @returns Filtered array, unique every object
 */
 export const createUniqueArray = (array = [], key) => {
  if(!array){
    return [];
  }
  const set = new Set();
  return array.filter(item => {
    let k = item[key];
    return set.has(k) ? false : set.add(k)
  })
}


/**
 * 
 * @param {string} userCategory - value user categorynya
 * @param {string | Array<string>} acceptCategoryType 
 */
export const checkUsersCategory = (userCategory, acceptCategoryType) => {
  let isHaveCategory = false;
  if (!userCategory) return isHaveCategory;
  switch (typeof acceptCategoryType) {
    case 'string': 
      if (userCategory.toLowerCase() === acceptCategoryType.toLowerCase()) {
        isHaveCategory = true;
      }
      break;
    default:
      for (const type of acceptCategoryType) {
        if (userCategory.toLowerCase() === type?.toLowerCase()) {
          isHaveCategory = true;
          break;
        }
      }
      break;
  }

  return isHaveCategory;
}

export const checkIsTokenExpired = (token) => {
  if (token){
  const jwt = jwtDecode(token);
  const currentTime = new Date();
  const expiredTime = new Date(jwt.exp * 1000);

  return isAfter(currentTime, expiredTime);
  }
}

export const checkScrollToBottom = (event) => {
  const {
    scrollHeight,
    scrollTop,
    clientHeight
  } = event.target;

  return Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1;
}

export const convertStringContentToBlob = (csvStringContent, mimeType = MIME.mimeOctetStream) => {
  return new Blob([csvStringContent], { type: mimeType });
}

export const downloadCSVStringContent = (csvStringContent, fileName = 'btt.csv', mimeType = MIME.mimeOctetStream) => {
  let downloadAddress = document.createElement("a");

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(new Blob([csvStringContent], {
      type: mimeType
    }), fileName);
  } else if (URL && 'download' in downloadAddress) {
    downloadAddress.href = URL.createObjectURL(new Blob([csvStringContent], { type: mimeType }));
    downloadAddress.setAttribute("download", fileName);
    document.body.appendChild(downloadAddress);
    downloadAddress.click();
    document.body.removeChild(downloadAddress)
  } else {
    // eslint-disable-next-line no-restricted-globals
    location.href = `data:application/octet-stream,${encodeURIComponent(csvStringContent)}`;
  }
}