import * as yup from "yup";

const regexNotIncludeComma = new RegExp(/^[^,]*$/, 'g')

const max15Digit = (fieldKey, t) =>
  yup
    .string()
    .default("")
    .transform((value) => value.replace(/[.,]/g, ""))
    .max(15, t(`BTT:ERROR_MAX_LENGTH_DIGIT`, { field: t(fieldKey), length: 15 }));

const notContainComma = (fieldKey, t) => 
  yup
    .string()
    .default("")
    .matches(regexNotIncludeComma, { message: t("BTT:ERROR_CANNOT_HAVE_COMMA", { field: t(fieldKey) }) });

export const validateDetailItem = (t) => {
  return () =>
    yup.object().shape({
      barcode: notContainComma("BTT:FIELD_BARCODE", t),
      product_name: notContainComma("BTT:FIELD_PRODUCT_NAME", t),
      uom_1: notContainComma("BTT:FIELD_UOM", t),
      qty_1: max15Digit("BTT:FIELD_QTY",t),
      price_uom_1: max15Digit("BTT:FIELD_PRICE",t),
      total_line_discount: max15Digit("BTT:FIELD_DISC",t),
      total_line_vat_amount: max15Digit("BTT:FIELD_PPN",t),
    });
};
