import React, { useCallback, useState } from "react";
import { Collapse } from "antd";
// import { CollapsedUpIcon, CollapsedDownIcon } from "../icons";
import styles from "./Accordion.module.css";
import { PRIMARY_COLOR } from "../../constant/propertiesConstant";

const CollapsedDownIcon = ({ id }) => (
  <svg id={id} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9814 14.2723L20.5784 5.59094C20.7645 5.40297 20.9854 5.25387 21.2286 5.15214C21.4718 5.05041 21.7324 4.99805 21.9956 4.99805C22.2589 4.99805 22.5195 5.05041 22.7627 5.15214C23.0058 5.25387 23.2268 5.40297 23.4129 5.59094C23.599 5.77886 23.7467 6.00197 23.8474 6.24753C23.9482 6.49309 24 6.75628 24 7.02207C24 7.28787 23.9482 7.55106 23.8474 7.79662C23.7467 8.04218 23.599 8.26529 23.4129 8.45321L11.9814 19.998L0.58713 8.49199C0.40099 8.30406 0.253333 8.08095 0.152592 7.8354C0.051851 7.58984 0 7.32665 0 7.06085C0 6.79506 0.051851 6.53187 0.152592 6.28631C0.253333 6.04075 0.40099 5.81764 0.58713 5.62971C0.773229 5.44175 0.994173 5.29264 1.23734 5.19091C1.48052 5.08918 1.74115 5.03683 2.00436 5.03683C2.26758 5.03683 2.52821 5.08918 2.77138 5.19091C3.01455 5.29264 3.2355 5.44175 3.4216 5.62971L11.9814 14.2723Z"
      fill={PRIMARY_COLOR}
    />
  </svg>
);

const CollapsedUpIcon = ({ id }) => (
  <svg id={id} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0186 10.7237L3.4216 19.4051C3.2355 19.5931 3.01455 19.7422 2.77138 19.8439C2.52821 19.9456 2.26758 19.998 2.00436 19.998C1.74115 19.998 1.48052 19.9456 1.23734 19.8439C0.994173 19.7422 0.773229 19.5931 0.58713 19.4051C0.40099 19.2172 0.253331 18.9941 0.15259 18.7485C0.0518492 18.5029 0 18.2398 0 17.974C0 17.7082 0.0518492 17.445 0.15259 17.1994C0.253331 16.9539 0.40099 16.7307 0.58713 16.5428L12.0186 4.99799L23.4129 16.504C23.599 16.692 23.7467 16.9151 23.8474 17.1606C23.9481 17.4062 24 17.6694 24 17.9352C24 18.201 23.9481 18.4642 23.8474 18.7097C23.7467 18.9553 23.599 19.1784 23.4129 19.3663C23.2268 19.5543 23.0058 19.7034 22.7627 19.8051C22.5195 19.9068 22.2589 19.9592 21.9956 19.9592C21.7324 19.9592 21.4718 19.9068 21.2286 19.8051C20.9854 19.7034 20.7645 19.5543 20.5784 19.3663L12.0186 10.7237Z"
      fill={PRIMARY_COLOR}
    />
  </svg>
);

const Header = ({ children }) => {
  return <div className={styles.header}>{children}</div>;
};

const Accordion = ({ id, activeKey, onChange, children }) => {
  const [panelKey, setPanelKey] = useState("");

  const onPanelChange = useCallback(
    (key) => {
      setPanelKey(key);
      if (onChange) onChange(key);
    },
    [onChange]
  );

  return (
    <Collapse
      accordion
      id={id ? id : "tableaccordion"}
      activeKey={activeKey ? activeKey : null}
      expandIconPosition="right"
      className={styles.accordion}
      expandIcon={({ isActive }) => (
        <span style={{ marginRight: 8 }}>
          {" "}
          {isActive ? (
            <CollapsedUpIcon id="btn-up" />
          ) : (
            <CollapsedDownIcon id="btn-down" />
          )}
        </span>
      )}
      onChange={onPanelChange}
      ghost
    >
      {children instanceof Array ? (
        children.map((child, index) => {
          return (
            <Collapse.Panel
              className={styles.panel}
              id={child.props.panelid ? child.props.panelid : `child_${index}`}
              header={
                child.props.header ? (
                  <Header>{child.props.header}</Header>
                ) : (
                  "Header"
                )
              }
              key={child.key}
            >
              {activeKey === child.key ? (
                <div className={styles.body}>
                  <div className={styles.expanded}>{child}</div>
                </div>
              ) : (
                <div></div>
              )}
            </Collapse.Panel>
          );
        })
      ) : (
        <Collapse.Panel
          id={
            children?.props?.panelid
              ? children?.props?.panelid
              : "children_panel"
          }
          className={styles.panel}
          showArrow={children?.props?.showArrow}
          header={
            children.props.header ? (
              <Header>{children.props.header}</Header>
            ) : (
              "Header"
            )
          }
          key={children.key}
        >
          <div className={styles.body}>
            <div className={styles.expanded}>{children}</div>
          </div>
        </Collapse.Panel>
      )}
    </Collapse>
  );
};

export default Accordion;
