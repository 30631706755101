import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import i18next from "i18next";
import * as baseApi from "../../api/baseApi";
import { API_URL, API_VERSION, API_PREFIX } from "../../constant/apiConstant";
import { PAGE } from "../serviceUtil";


const INITIAL_STATE = {
  initiate: {},
  list: [],
  list_detail: [],
  detail: {},
  filter: '',
  is_loading: false,
  has_error: false,
  error: null,
  selected_id: [],
  list_history: [],
  initiate_history: {}
};

export const getInitiateBTT = createAsyncThunk(
  "getInitiateBTT",
  async(params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/btt/initiateGetList`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
        return rejectWithValue(error)
      }
      return result;
  }
);

export const getListBTT = createAsyncThunk(
  "getListBTT",
  async(params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/btt`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
        return rejectWithValue(error)
      }
      return result;
  }
);

export const getListDetailBTT = createAsyncThunk(
  "getListDetailBTT",
  async(listId = [], { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/btt`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doPost(url, token, { id: listId })
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
        return rejectWithValue(error)
      }
      return result;
  }
);

export const saveFileBTT = createAsyncThunk(
  "saveFileBTT",
  async (data, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/btt-history`;
    const { auth } = getState();
    const token = auth.token;
    try {

      const response = await baseApi.doPost(url, token, data)
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
      return rejectWithValue(error)
    }
    return result;
  }
);

export const getInitiateHistoryBTT = createAsyncThunk(
  "getInitiateHistoryBTT",
  async(params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/btt-history/initiateGetList`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
        return rejectWithValue(error)
      }
      return result;
  }
);

export const getListHistoryBTT = createAsyncThunk(
  "getListHistoryBTT",
  async(params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/btt-history`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
        return rejectWithValue(error)
      }
      return result;
  }
);

export const downloadHistoryBTT = createAsyncThunk(
  "downloadHistoryBTT",
  async(id, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/btt-history/download/${id}`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doPost(url, token);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
        return rejectWithValue(error)
      }
      return result;
  }
);

const bttSlice = createSlice({
  name: 'btt',
  initialState: INITIAL_STATE,
  reducers: {
    addOrRemoveExistPoId: (state, { payload }) => {
      const findExist = state.selected_id.find((currentId) => currentId === payload)
      if (findExist) {
        state.selected_id = state.selected_id.filter((currentId) => currentId !== findExist);
      } else {
        state.selected_id.push(payload);
      }
    },
    resetAllState: () => {
      return INITIAL_STATE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInitiateBTT.pending, (state) => {
      state.is_loading = true;
      state.has_error = false;
      state.error = null;
    });
    builder.addCase(getInitiateBTT.fulfilled, (state, { payload }) => {
      state.is_loading = false;
      state.initiate = payload.response;
    });
    builder.addCase(getInitiateBTT.rejected, (state, { payload }) => {
      state.is_loading = false;
      state.has_error = true;
      state.error = payload.response;
    });

    builder.addCase(getListBTT.pending, (state) => {
      state.is_loading = true;
      state.has_error = false;
      state.error = null;
    });
    builder.addCase(getListBTT.fulfilled, (state, { payload }) => {
      state.is_loading = false;
      state.list = payload.response;
    });
    builder.addCase(getListBTT.rejected, (state, { payload }) => {
      state.is_loading = false;
      state.has_error = true;
      state.error = payload.response;
    });

    builder.addCase(getListDetailBTT.pending, (state) => {
      state.is_loading = true;
      state.has_error = false;
      state.error = null;
    });
    builder.addCase(getListDetailBTT.fulfilled, (state, { payload }) => {
      state.is_loading = false;
      state.list_detail = payload.response;
    });
    builder.addCase(getListDetailBTT.rejected, (state, { payload }) => {
      state.is_loading = false;
      state.has_error = true;
      state.error = payload.response;
    });

    builder.addCase(getInitiateHistoryBTT.pending, (state) => {
      state.is_loading = true;
      state.has_error = false;
      state.error = null;
    });
    builder.addCase(getInitiateHistoryBTT.fulfilled, (state, { payload }) => {
      state.is_loading = false;
      state.initiate_history = payload.response;
    });
    builder.addCase(getInitiateHistoryBTT.rejected, (state, { payload }) => {
      state.is_loading = false;
      state.has_error = true;
      state.error = payload.response;
    });

    builder.addCase(getListHistoryBTT.pending, (state) => {
      state.is_loading = true;
      state.has_error = false;
      state.error = null;
    });
    builder.addCase(getListHistoryBTT.fulfilled, (state, { payload }) => {
      state.is_loading = false;
      state.list_history = payload.response;
    });
    builder.addCase(getListHistoryBTT.rejected, (state, { payload }) => {
      state.is_loading = false;
      state.has_error = true;
      state.error = payload.response;
    });
  }
});

export const bttAction = bttSlice.actions;

export default bttSlice.reducer;