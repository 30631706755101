import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInitiateBTT,
  getListBTT,
  bttAction
} from "../../../../../services/main/bttReducer";
import { useHistory } from "react-router-dom";
import { Box, CloseButton, Icon, Input, InputGroup, InputRightElement, Stack, Text, filter } from "@chakra-ui/react";
import HeadTitle from "../../../../../components/HeadTitle";
import { useTranslation } from "react-i18next";
import { GRAY_COLOR, PRIMARY_COLOR, RED_COLOR, WHITE_COLOR } from "../../../../../constant/propertiesConstant";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import { AddIcon, SearchIcon, UploadIcon } from "../../../../../components/icons";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import { Pagination, Select, Table } from "antd";
import DateUtil, { FORMAT } from "../../../../../utils/dateUtil";
import CustomCheckBox from "../../../../../components/customCheckBox/CustomCheckBox";
import { PAGE, defaulPageLimitOption } from "../../../../../services/serviceUtil";
import InputDatePicker from "../../../../../components/datePicker/InputDatePicker";
import { selectOptFilterUserTheme } from "../../../../../constant/themeConstant";
import StringUtil from "../../../../../utils/stringUtil";
import { getListCustomerDDS } from "../../../../../services/main/proccessApprovePoReducer";
import DropDownCustom from "../../../../../components/DropDownCustom";
import { getListCustomer } from "../../../../../services/main/customerReducer";
import { checkScrollToBottom } from "../../../../../services/util";
import { selectDropDownBTT } from "../../../../../constant/selectOptConstant";
import { getListMasterStore } from "../../../../../services/main/masterStoreReducer";

const datePickerProps = { maxDate: new Date() };

const ListBTT = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { 
    list, 
    initiate,
    is_loading,
    selected_id
  } = useSelector((state) => state.btt);
  const dispatch = useDispatch();
  const [page, setPage] = useState(PAGE.DEFAULT_PAGE);
  const [limit, setLimit] = useState(PAGE.DEFAULT_PAGE_LIMIT);
  const [filters, setFilters] = useState({ 
    customer_id: null,
    po_date: null,
    po_number: "",
    store_id: null
   });

  const [selectFilterBy, setSelectFilterBy] = useState(null);
  const [filterSearch, setFilterSearch] = useState("");
  const [poDate, setPODate] = useState("");
  const [storeId, setStoreId] = useState(null);

  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  const [tableLoading, setTableLoading] = useState(false);
  const isFirstRunRef = useRef({ list: true, initiate: true, store: true });
  const [customerId, setCustomerId] = useState(null);
  
  // Customer
  const [customer, setCustomer] = useState({
    data: [],
    page: PAGE.DEFAULT_PAGE,
    limit: PAGE.DEFAULT_PAGE_LIMIT,
    filter: ''
  });

  // Store
  const [store, setStore] = useState({
    data: [],
    page: PAGE.DEFAULT_PAGE,
    limit: PAGE.DEFAULT_PAGE_LIMIT,
    filter: {
      customer_id: null
    },
    search: ''
  });


  const [filterCustomerName, setFilterCustomerName] = useState("Suriatama Mahkota Kencana");
  const [filterStore, setFilterStore] = useState("");

  const fetchCustomer = useCallback((page = PAGE.DEFAULT_PAGE, limit = PAGE.DEFAULT_PAGE_LIMIT) => {
    setTableLoading(true);
    const filter = StringUtil.formatFilter({
      customer_name: `like ${filterCustomerName}`
    });
    dispatch(getListCustomer({ page, limit, filter })).then((response) => {
      if (!response?.payload.ok || !response.payload.response) return;
      const data = response.payload.response;

      setCustomer((prev) => ({
        ...prev,
        page,
        limit,
        filter,
        data: [...prev.data, ...data]
      }));

      if (Array.isArray(data) && data.length > 0) {
        const customerId = data[0].id;
        setCustomerId(customerId)
        setStore((prev) => ({ ...prev, filter: { ...prev.filter , customer_id: customerId} }));
      }

      setTableLoading(false);
    });
  }, [dispatch, filterCustomerName]);

  const fetchCustomerSearch = useCallback(({ name }) => {
    const filter = StringUtil.formatFilter({
      customer_name: `like ${name}`
    });
    dispatch(getListCustomer({ 
      page: PAGE.DEFAULT_PAGE, 
      limit: PAGE.DEFAULT_PAGE_LIMIT, 
      filter 
    })).then((response) => {
      if (!response?.payload.ok || !response.payload.response) return;
      const data = response.payload.response;

      setCustomer({
        page: PAGE.DEFAULT_PAGE,
        limit: PAGE.DEFAULT_PAGE_LIMIT,
        filter,
        data: [...data]
      });
    });
  }, [dispatch]);

  const fetchStore = useCallback((page = PAGE.DEFAULT_PAGE, limit = PAGE.DEFAULT_PAGE_LIMIT) => {
    const filter = StringUtil.formatFilter({
      customer_id: `eq ${customerId}`
    });
    dispatch(getListMasterStore({ page, limit, filter })).then((response) => {
      if (!response?.payload.ok || !response.payload.response) return;
      const data = response.payload.response;

      setStore((prev) => ({
        ...prev,
        page,
        limit,
        filter,
        data: [...prev.data, ...data]
      }));

    });
  }, [dispatch, customerId]);

  const fetchStoreSearch = useCallback(({ name }) => {
    const filter = StringUtil.formatFilter({
      store_name: `like ${name}`,
      customer_id: `eq ${customerId}`
    });
    dispatch(getListMasterStore({ 
      page: PAGE.DEFAULT_PAGE, 
      limit: PAGE.DEFAULT_PAGE_LIMIT, 
      filter 
    })).then((response) => {
      if (!response?.payload.ok || !response.payload.response) return;
      const data = response.payload.response;

      setStore({
        page: PAGE.DEFAULT_PAGE,
        limit: PAGE.DEFAULT_PAGE_LIMIT,
        filter,
        data: [...data]
      });
    });
  }, [dispatch, customerId]);



  const handleChangeFilter = useCallback((namespace, value) => {
    switch(namespace) {
      case "po_date":
        setPODate(value);
        break;
      case "po_number":
        setFilterSearch(value);
        break;
      case "store":
        setStoreId(value);
        break;
      default:
    }
  }, []);

  const resetFilter = useCallback(() => {
    setFilters((prev) => ({
      customer_id: prev.customer_id,
      po_date: null,
      po_number: "",
      store_id: null
    }));


    setCustomerId((prev) => prev);
    setSelectFilterBy(null);
    setPODate(null);
    setFilterSearch("");
    setStoreId(null);
  }, []);

  const applyFilter = useCallback(() => {
    // isFirstRunRef.current.list = true;
    setPage(1);
    const currentFilter = {
      po_date: poDate,
      store_id: storeId
    }

    if (selectFilterBy) currentFilter[selectFilterBy] = filterSearch;

    setFilters((prev) => ({ ...prev, ...currentFilter }));
  }, [filterSearch, poDate, storeId, selectFilterBy])

  const handleScrollStore = useCallback((event) => {
    if (checkScrollToBottom(event)) {
      fetchStore(store.page + 1);
    }
  }, [store, fetchStore])

  const handleSearchStoreName = useCallback((event) => {
    const name = event?.target?.value ?? "";
    setFilterStore(name);
    fetchStoreSearch({ name });
  }, [fetchStoreSearch]);

  const countData = useMemo(() => initiate?.count_data ?? 0, [initiate]);

  const handleSelectPagination = useCallback((value) => {
    setPage(1);
    setLimit(value);
  }, []);

  const handleCloseButtonSearch = () => {
    setButtonSearchOrClose("search");
    setFilterSearch("");
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    if (value) {
      setButtonSearchOrClose("close");
    } else {
      setButtonSearchOrClose("search");
    }
    setFilterSearch(value);
  }

  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };

  const handleOnClickCheckbox = useCallback((selectedId) => {
    if (selectedId) {
      dispatch(bttAction.addOrRemoveExistPoId(selectedId))
    }
  }, [dispatch]);

  const handleOnCreateBTT = useCallback(() => {
    history.push(ROUTES_USER_SELLER.BTT.CREATE, selected_id);
  }, [history, selected_id]);

  const columns = useMemo(
    () => [
      {
        dataIndex: "id",
        width: 100,
        fixed: "left",
        render: (value) => (
          <CustomCheckBox 
            onChange={() => handleOnClickCheckbox(value)}
            isChecked={selected_id.includes(value)}
          />
        ),
      },
      {
        title: (
          <Box display="flex" alignItems="center" cursor="pointer">
            {t("BTT:FIELD_PO_NUMBER")}
          </Box>
        ),
        dataIndex: "po_number",
      },
      {
        title: (
          <Box display="flex" alignItems="center" cursor="pointer">
            {t("BTT:FIELD_STORE_NAME")}
          </Box>
        ),
        dataIndex: "store_name",
      },
      {
        title: (
          <Box display="flex" alignItems="center" cursor="pointer">
            {t("BTT:FIELD_PO_DATE")}
          </Box>
        ),
        dataIndex: "transaction_date",
        render: (value) => DateUtil.formatDate(value, FORMAT.DEFAULT_DATE),
      },
      {
        title: (
          <Box display="flex" alignItems="center" cursor="pointer">
            {t("BTT:FIELD_STATUS")}
          </Box>
        ),
        dataIndex: "created_at",
        render: (value) => {
          return (value && !value.includes('0001-01-01')) ? t("BTT:BTT_CREATED_AT", { date: DateUtil.formatUTCToLocalDate(value, FORMAT.DEFAULT_DATE) }) : <Text color={RED_COLOR}>{t("BTT:BTT_NOT_CREATED")}</Text>
        }
      }
    ],
    [t, selected_id, handleOnClickCheckbox]
  );

  const memoizeFilter = useMemo(() => StringUtil.formatFilter({ 
    customer_id: `eq ${customerId}`,
    po_date: `eq ${DateUtil.formatDate(filters.po_date, FORMAT.DEFAULT_DATE_API)}`,
    po_number: `like ${filters.po_number}`,
    store_id: `eq ${filters.store_id}`
  }), [filters, customerId]);

  // Fetch List Customer
  useEffect(() => {
    fetchCustomer();
  }, [fetchCustomer]);

  // Fetch Store
  useEffect(() => {
    if (isFirstRunRef.current.store) {
      isFirstRunRef.current.store = false;
      return;
    }
    fetchStore();
  }, [fetchStore])

  // Fetch Initiate BTT
  useEffect(() => {
    if (isFirstRunRef.current.initiate) {
      isFirstRunRef.current.initiate = false;
      return;
    }
    dispatch(getInitiateBTT({ filter: memoizeFilter }));
  }, [dispatch, memoizeFilter]);

  //  Fetch List BTT
  useEffect(() => {
    if (isFirstRunRef.current.list) {
      isFirstRunRef.current.list = false;
      return;
    }
    dispatch(getListBTT({ page, limit, filter : memoizeFilter}));
  }, [dispatch, page, limit, memoizeFilter]);

  useEffect(() => () => {
    dispatch(bttAction.resetAllState());
  }, [dispatch]);

  return (
    <Box padding={4}>
      <HeadTitle
        title={t("BTT:TITLE")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("BTT:BREADCRUMS.HOME")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("BTT:BREADCRUMS.TRANSACTION")}
            </span>{" "}
            - {t("BTT:BREADCRUMS.BTT")}
          </>
        }
        button={
          <ButtonPrimary
            onClick={handleOnCreateBTT}
            text={t("BTT:CREATE_BTT")}
            isDisabled={selected_id.length === 0}
          />
        }
      />
      <Box marginTop={6}>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
        >
          <InputDatePicker 
            key={+new Date()}
            width="200px"
            placeholder={t("COMMON:TRANSACTION_DATE")}
            value={poDate}
            onChange={(value) => handleChangeFilter("po_date", value)}
            datePickerProps={datePickerProps}
            
          />
          <Box className="my-select-container">
            <Select 
              style={selectOptFilterUserTheme}
              placeholder={`${t("COMMON:SELECT")} ${t("BTT:STORE")}`}
              size="large"
              value={storeId}
              allowClear
              onPopupScroll={handleScrollStore}
              options={store.data?.map((item) => ({ value: item.id, label: item.store_name }))}
              onChange={(value) => handleChangeFilter("store", value)}
              dropdownRender={(menu) => (
                <DropDownCustom
                  value={filterStore}
                  menu={menu}
                  search={handleSearchStoreName}
                />
              )}
            />
          </Box>
          <Box className="my-select-container">
            <Select 
              style={selectOptFilterUserTheme}
              placeholder={t("COMMON:SELECT_FILTER_BY")}
              size="large"
              onChange={setSelectFilterBy}
              value={selectFilterBy}
              options={selectDropDownBTT.map((value) => ({ ...value, label: t(value.label)}))}
            />
          </Box>
          <InputGroup
            width="257px"
            height="40px"
            backgroundColor="white"
            borderRadius={5}
          >
            <Input
              style={{ border: `1px solid #d9d9d9 !important`}}
              type="text"
              fontSize="14px"
              name="search"
              id="search"
              value={filterSearch}
              onChange={handleChangeSearch}
              isDisabled={!selectFilterBy}
              placeholder={
                selectFilterBy
                  ? `${t("COMMON:SELECT_FILTER_BY")} ${
                      selectFilterBy
                    }`
                  : ""
              }
              
            />
            {renderSearchOrCloseButton()}
          </InputGroup>
          <ButtonPrimary
              text={t("COMMON:BUTTON_APPLY")}
              backgroundColor={PRIMARY_COLOR}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={applyFilter}
              id="BTN-UserSeller-BTT-Apply"
            />
            <Text
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
        </Stack>
      </Box>
      <Table
        dataSource={list}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{ y: 400 }}
        loading={is_loading || tableLoading}
        footer={() => (
          <Box
            backgroundColor="#E5EBF1"
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            <Box display="flex" alignItems="center" marginX={2}>
              <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
              <Select
                border="1px solid"
                size="sm"
                width={20}
                _focus={{ outline: "none" }}
                value={limit}
                disabled={is_loading}
                onChange={handleSelectPagination}
                options={defaulPageLimitOption} />
            </Box>
            <Box>
              <Pagination
                size="small"
                current={page}
                total={countData}
                onChange={(value) => setPage(value)}
                showSizeChanger={false}
                pageSize={limit}
                disabled={is_loading}
              />
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

export default ListBTT;
