const formatPhotoUrl = ({ filename, path, host }) => {
  if (!filename || !path || !host) return "";
  let photoUrl = "";
  if (path && path.endsWith("/") && !path.includes(".")) {
    photoUrl = host + path + filename;
  } else {
    photoUrl = host + path;
  }

  return photoUrl;
};

const formatPhotoNormal = ({ filename, path, host }) => {
  let url = "";
  const photoFull = host?.concat("/").concat(path).concat(filename);
  const photoFinal = photoFull ? photoFull : null;
  url = photoFinal;
  return url;
};

function formatNumberCurrency(input) {
  if (input === undefined || input === null) return "";
  let str = input.toString();
  let parts = str.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  
  if (!parts[0]) parts[0] = '0';

  return parts.join(',');
}

function parseFormattedCurrency(input) {
  let numberString = String(input).replace(/\./g, '').replace(',', '.');
  numberString = numberString.replace(/^0+(?=[1-9])/, '');
  return numberString;
}

const FormatUtil = {
  formatPhotoUrl,
  formatPhotoNormal,
  formatNumberCurrency,
  parseFormattedCurrency
};

export default FormatUtil;
