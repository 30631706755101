import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInitiateHistoryBTT,
  getListHistoryBTT,
  downloadHistoryBTT,
  bttAction
} from "../../../../../services/main/bttReducer";
import { useHistory } from "react-router-dom";
import { Box, CloseButton, Icon, Input, InputGroup, InputRightElement, Stack, Text, filter } from "@chakra-ui/react";
import HeadTitle from "../../../../../components/HeadTitle";
import { useTranslation } from "react-i18next";
import { PRIMARY_COLOR, WHITE_COLOR } from "../../../../../constant/propertiesConstant";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import { SearchIcon } from "../../../../../components/icons";
import { Pagination, Select, Table } from "antd";
import DateUtil, { FORMAT } from "../../../../../utils/dateUtil";
import CustomCheckBox from "../../../../../components/customCheckBox/CustomCheckBox";
import { PAGE, defaulPageLimitOption } from "../../../../../services/serviceUtil";
import InputDatePicker from "../../../../../components/datePicker/InputDatePicker";
import { selectOptFilterUserTheme } from "../../../../../constant/themeConstant";
import StringUtil from "../../../../../utils/stringUtil";
import DropDownCustom from "../../../../../components/DropDownCustom";
import { getListCustomer } from "../../../../../services/main/customerReducer";
import { checkScrollToBottom } from "../../../../../services/util";
import { selectDropDownHistoryBTT } from "../../../../../constant/selectOptConstant";
import { API_URL } from "../../../../../constant/apiConstant";

const ListBTT = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { 
    list_history, 
    initiate_history,
    is_loading,
    selected_id
  } = useSelector((state) => state.btt);
  const dispatch = useDispatch();
  const [page, setPage] = useState(PAGE.DEFAULT_PAGE);
  const [limit, setLimit] = useState(PAGE.DEFAULT_PAGE_LIMIT);
  const [filters, setFilters] = useState({ 
    customer_id: null,
    po_date: null,
    file_name: ""
   });
   const [downloadLoading, setDownloadLoading] = useState(false);
   const isFirstRunRef = useRef({ list: true, initiate: true });

  const [selectFilterBy, setSelectFilterBy] = useState(null);
  const [filterSearch, setFilterSearch] = useState("");
  const [poDate, setPODate] = useState("");

  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  
  // Customer
  const [customer, setCustomer] = useState({
    data: [],
    page: PAGE.DEFAULT_PAGE,
    limit: PAGE.DEFAULT_PAGE_LIMIT,
    filter: ''
  });

  const [filterCustomerName, setFilterCustomerName] = useState("Suriatama Mahkota Kencana");

  const fetchCustomer = useCallback((page = PAGE.DEFAULT_PAGE, limit = PAGE.DEFAULT_PAGE_LIMIT) => {
    const filter = StringUtil.formatFilter({
      customer_name: `like ${filterCustomerName}`
    });
    dispatch(getListCustomer({ page, limit, filter })).then((response) => {
      if (!response?.payload.ok || !response.payload.response) return;
      const data = response.payload.response;

      setCustomer((prev) => ({
        ...prev,
        page,
        limit,
        filter,
        data: [...prev.data, ...data]
      }));
    });
  }, [dispatch, filterCustomerName]);

  const fetchCustomerSearch = useCallback(({ name }) => {
    const filter = StringUtil.formatFilter({
      customer_name: `like ${name}`
    });
    dispatch(getListCustomer({ 
      page: PAGE.DEFAULT_PAGE, 
      limit: PAGE.DEFAULT_PAGE_LIMIT, 
      filter 
    })).then((response) => {
      if (!response?.payload.ok || !response.payload.response) return;
      const data = response.payload.response;

      setCustomer({
        page: PAGE.DEFAULT_PAGE,
        limit: PAGE.DEFAULT_PAGE_LIMIT,
        filter,
        data: [...data]
      });
    });
  }, [dispatch]);

  const handleChangeFilter = useCallback((namespace, value) => {
    switch(namespace) {
      case "po_date":
        setPODate(value);
        break;
      case "file_name":
        setFilterSearch(value);
        break;
      default:
    }
  }, []);

  const resetFilter = useCallback(() => {
    setFilters({
      po_date: null,
      file_name: ""
    });
    
    setSelectFilterBy(null);
    setPODate(null);
    setFilterSearch("");
  }, []);

  const applyFilter = useCallback(() => {
    setPage(1);
    const currentFilter = {
      po_date: poDate,
    }

    if (selectFilterBy) currentFilter[selectFilterBy] = filterSearch;


    setFilters((prev) => ({ ...prev, ...currentFilter }));
  }, [filterSearch, poDate, selectFilterBy])

  const handleScrollCustomer = useCallback((event) => {
    if (checkScrollToBottom(event)) {
      fetchCustomer(customer.page + 1);
    }
  }, [customer, fetchCustomer])

  const handleSearchCustomerName = useCallback((event) => {
    const name = event?.target?.value ?? "";
    setFilterCustomerName(name);
    fetchCustomerSearch({ name });
  }, [fetchCustomerSearch]);

  const countData = useMemo(() => initiate_history?.count_data ?? 0, [initiate_history]);

  const handleSelectPagination = useCallback((value) => {
    setPage(1);
    setLimit(value);
  }, []);

  const handleCloseButtonSearch = () => {
    setButtonSearchOrClose("search");
  };

  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };

  const handleDownloadHistoryBTT = useCallback((id) => {
    dispatch(downloadHistoryBTT(id)).then((res) => {
      if (res.payload.ok) {
        const filePath = res.payload.response.file_name;
        const fullURL = `${API_URL}/${filePath}`;
        window.open(fullURL, '_blank');
      }
    })
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        title: (
          <Box display="flex" alignItems="center" cursor="pointer">
              {t("BTT:FIELD_FILENAME")}
          </Box>
        ),
        dataIndex: "btt_name",
        render: (value, original) => {
          return <Text 
          color="#3182CE"
          onClick={() => handleDownloadHistoryBTT(original.id)}
          cursor="default"
          _hover={{
            textDecoration: "underline",
            cursor: "pointer"
          }}>
            {value}
           </Text>
        }
      },
      {
        title: (
          <Box display="flex" alignItems="center" cursor="pointer">
            {t("BTT:FIELD_CREATED_DATE")}
          </Box>
        ),
        dataIndex: "created_date",
        render: (value) => DateUtil.formatDate(DateUtil.UTCToLocalDate(value), FORMAT.DEFAULT_DATE),
      },
      {
        title: (
          <Box display="flex" alignItems="center" cursor="pointer">
            {t("BTT:FIELD_TIME")}
          </Box>
        ),
        dataIndex: "created_date",
        render: (value) => DateUtil.formatDate(DateUtil.UTCToLocalDate(value), FORMAT.DEFAULT_TIME),
      }
    ],
    [t]
  );

  const memoizeFilter = useMemo(() => StringUtil.formatFilter({ 
    customer_id: `eq ${filters.customer_id}`,
    po_date: `eq ${DateUtil.formatDate(filters.po_date, FORMAT.DEFAULT_DATE_API)}`,
    file_name: `like ${filters.file_name}`
  }), [filters]);

  // Fetch List Customer
  useEffect(() => {
    fetchCustomer();
  }, [fetchCustomer]);

  // Fetch Initiate BTT
  useEffect(() => {
    dispatch(getInitiateHistoryBTT({ filter: memoizeFilter }));
  }, [dispatch, memoizeFilter]);

  //  Fetch List BTT
  useEffect(() => {
    dispatch(getListHistoryBTT({ page, limit, filter : memoizeFilter}));
  }, [dispatch, page, limit, memoizeFilter]);

  return (
    <Box padding={4}>
      <HeadTitle
        title={t("BTT:HISTORY_TITLE")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("BTT:BREADCRUMS.HOME")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("BTT:BREADCRUMS.TRANSACTION")}
            </span>{" "}
            - {t("BTT:BREADCRUMS.BTT")}
          </>
        }
      />
      <Box marginTop={6}>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
        >
          {/* <InputDatePicker 
            key={+new Date()}
            width="200px"
            placeholder={t("COMMON:TRANSACTION_DATE")}
            value={poDate}
            onChange={(value) => handleChangeFilter("po_date", value)}
          /> */}
          <Box className="my-select-container">
            <Select 
              style={selectOptFilterUserTheme}
              placeholder={t("COMMON:SELECT_FILTER_BY")}
              size="large"
              onChange={setSelectFilterBy}
              value={selectFilterBy}
              options={selectDropDownHistoryBTT.map((value) => ({ ...value, label: t(value.label)}))}
            />
          </Box>
          <InputGroup
            width="257px"
            height="40px"
            backgroundColor="white"
            borderRadius={5}
          >
            <Input
              style={{ border: `1px solid #d9d9d9 !important`}}
              type="text"
              fontSize="14px"
              name="search"
              id="search"
              value={filterSearch}
              onChange={(e) => setFilterSearch(e.target.value)}
              isDisabled={!selectFilterBy}
              placeholder={
                selectFilterBy?.value
                  ? `${t("COMMON:SELECT_FILTER_BY")} ${
                      selectFilterBy?.label
                    }`
                  : ""
              }
            />
            {renderSearchOrCloseButton()}
          </InputGroup>
          <ButtonPrimary
              text={t("COMMON:BUTTON_APPLY")}
              backgroundColor={PRIMARY_COLOR}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={applyFilter}
              id="BTN-UserSeller-BTT-Apply"
            />
            <Text
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
        </Stack>
      </Box>
      <Table
        dataSource={list_history}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{ y: 400 }}
        loading={is_loading}
        footer={() => (
          <Box
            backgroundColor="#E5EBF1"
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            <Box display="flex" alignItems="center" marginX={2}>
              <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
              <Select
                border="1px solid"
                size="sm"
                width={20}
                _focus={{ outline: "none" }}
                value={limit}
                disabled={is_loading}
                onChange={handleSelectPagination}
                options={defaulPageLimitOption} />
            </Box>
            <Box>
              <Pagination
                size="small"
                current={page}
                total={countData}
                onChange={(value) => setPage(value)}
                showSizeChanger={false}
                pageSize={limit}
                disabled={is_loading}
              />
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

export default ListBTT;