import {
  Box,
  CloseButton,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "../../../../../../components/button/ButtonPrimary";
import FormatUtil from "../../../../../../utils/formatUtil";
import { validateDetailItem } from "../createBTTSchema";
import ValidationError from "../../../../../../components/validationErrorMessage/ValidationError";
import { REGEX_CONSTANT } from "../../../../../../constant/regexConstant"


const ModalDetailItem = ({ id, data, onSubmit, onCancel, isOpen }) => {
  const { t } = useTranslation();
  const isFirstOpenRef = useRef(true);
  const [total, setTotal] = useState(0);
  const [errorTotal, setErrorTotal] = useState("");

  const handleOnSubmit = useCallback((values) => {
    if (onSubmit && !errorTotal) onSubmit({ ...values, total_line_net_amount: total });
  }, [onSubmit, total, errorTotal]);

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    setValues,
  } = useFormik({
    initialValues: {
      barcode: "",
      product_name: "",
      qty_1: 0,
      uom_1: "",
      price_uom_1: 0,
      total_line_discount: 0,
      total_line_vat_amount: 0,
      total_line_net_amount: 0
    },
    validationSchema: validateDetailItem(t),
    onSubmit: handleOnSubmit,
  });

  const validateTotalNetAmount = useCallback((value) => {
    const replacedString = String(value).replace(/[.,-]/g, '');
    const replacedStringNumber = String(value).replace(/[.,]/g, '');
    let errorMessage = "";
    if (replacedString.length > 16) {
      errorMessage = t("BTT:ERROR_MAX_LENGTH_DIGIT", { field: t("BTT:FIELD_TOTAL"), length: 16});
    }

    if (replacedStringNumber && !REGEX_CONSTANT.INTEGER.test(replacedStringNumber)) {
      let valueNumber = Number.POSITIVE_INFINITY;
      
      try {
        valueNumber = parseFloat(replacedStringNumber);

        if (valueNumber < 0) {
          errorMessage = t("BTT:ERROR_NON_NEGATIVE", { field: t("BTT:FIELD_TOTAL") });
        }
      } catch (ignored) {}

    }

    return errorMessage;
  }, [t]);

  const handleNumberTypeChange = useCallback((namespace, value) => {
    if (namespace === 'total_line_net_amount') {
      setTotal(value);
      setErrorTotal(validateTotalNetAmount(value));
    } else {
      setFieldValue(namespace, value);
    }
  }, [setFieldValue, validateTotalNetAmount]);

  const handleOnCancel = useCallback(() => {
    if(onCancel) onCancel();
  }, [onCancel]);

  const calculateTotalLineNetAmount = useCallback((values) => {
    const price = Number.isNaN(values.price_uom_1) ? 0 : values.price_uom_1;
    const discount = Number.isNaN(values.total_line_discount) ? 0 : values.total_line_discount;
    const vat = Number.isNaN(values.total_line_vat_amount) ? 0 : values.total_line_vat_amount;
    
    handleNumberTypeChange("total_line_net_amount", price + vat - discount);
    
  }, [handleNumberTypeChange])


  useEffect(() => {
    if (data) {
      isFirstOpenRef.current = true
      setValues({ ...data });
      setTotal(data.total_line_net_amount);
      setErrorTotal(false);
    }
  }, [data, setValues]);

  useEffect(() => {
    if (isFirstOpenRef.current) {
      isFirstOpenRef.current = false;
      return;
    }
    calculateTotalLineNetAmount(values);
  }, [values, calculateTotalLineNetAmount]);

  return (
    <Modal id={id} isOpen={isOpen} size="3xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader marginTop={3}>
          <Flex justifyContent="space-between" width="100%" paddingRight={2}>
            <Text fontSize="2xl" paddingX={4}>{t("BTT:TITLE_DETAIL_PRODUCT")}</Text>
            <CloseButton size="lg" onClick={handleOnCancel} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Stack gap={2} marginBottom={5} paddingX={4}>
              <Flex>
                <Box width="16rem" marginTop={2}>
                  {t("BTT:FIELD_BARCODE")}
                </Box>
                <Box width="100%" maxWidth="485px">
                  <Input
                    id="barcode"
                    name="barcode"
                    type="text"
                    value={values.barcode}
                    onChange={handleChange}
                    maxWidth="485px"
                  />
                  {errors.barcode && <ValidationError text={errors.barcode} />}
                </Box>
              </Flex>
              <Flex>
                <Box width="16rem" marginTop={2}>
                  {t("BTT:FIELD_PRODUCT_NAME")}
                </Box>
                <Box width="100%" maxWidth="485px">
                  <Input
                    id="product_name"
                    name="product_name"
                    value={values.product_name}
                    onChange={handleChange}
                    type="text"
                    maxWidth="485px"
                  />
                  {errors.product_name && <ValidationError text={errors.product_name} />}
                </Box>
              </Flex>
              <Flex>
                <Box width="15.5rem" marginTop={2}>
                  {t("BTT:FIELD_QTY")}
                </Box>
                <Box width="100%" maxWidth="485px">
                  <NumberInput
                    id="qty_1"
                    name="qty_1"
                    onChange={(_, value) => handleNumberTypeChange("qty_1", value)}
                    isValidCharacter={(value) => value.match(/^[0-9]$/)}
                    min={0}
                    value={values.qty_1}
                    width="100%"
                    maxWidth="485px"
                  >
                    <NumberInputField />
                  </NumberInput>
                  {errors.qty_1 && <ValidationError text={errors.qty_1} />}

                </Box>
              </Flex>
              <Flex>
                <Box width="16rem" marginTop={2}>
                  {t("BTT:FIELD_UOM")}
                </Box>
                <Box width="100%" maxWidth="485px">
                  <Input
                    id="uom_1"
                    name="uom_1"
                    value={values.uom_1}
                    onChange={handleChange}
                    type="text"
                    maxWidth="485px"
                  />
                  {errors.uom_1 && <ValidationError text={errors.uom_1} />}
                </Box>
              </Flex>
              <Flex>
                <Box width="15.5rem" marginTop={2}>
                  {t("BTT:FIELD_PRICE")}
                </Box>
                <Box width="100%" maxWidth="485px">
                  <NumberInput
                    id="price_uom_1"
                    name="price_uom_1"
                    onChange={(_, value) => handleNumberTypeChange("price_uom_1", value)}
                    min={0}
                    defaultValue={values.price_uom_1}
                    width="100%"
                    maxWidth="485px"
                    isValidCharacter={(value) => value.match(/^[0-9.,]$/)}
                    format={FormatUtil.formatNumberCurrency}
                    parse={FormatUtil.parseFormattedCurrency}
                    pattern="(-)?([0-9.,]+)?"
                  >
                    <NumberInputField />
                  </NumberInput>
                  {errors.price_uom_1 && <ValidationError text={errors.price_uom_1} />}
                </Box>
              </Flex>
              <Flex>
                <Box width="15.5rem" marginTop={2}>
                  {t("BTT:FIELD_DISC")}
                </Box>
                <Box width="100%" maxWidth="485px">
                  <NumberInput
                    id="total_line_discount"
                    name="total_line_discount"
                    onChange={(_, value) => handleNumberTypeChange("total_line_discount", value)}

                    min={0}
                    defaultValue={values.total_line_discount}
                    width="100%"
                    maxWidth="485px"
                    isValidCharacter={(value) => value.match(/^[0-9.,]$/)}
                    format={FormatUtil.formatNumberCurrency}
                    parse={FormatUtil.parseFormattedCurrency}
                    pattern="(-)?([0-9.,]+)?"
                  >
                    <NumberInputField />
                  </NumberInput>
                  {errors.total_line_discount && <ValidationError text={errors.total_line_discount} />}
                </Box>
              </Flex>
              <Flex>
                <Box width="15.5rem" marginTop={2}>
                  {t("BTT:FIELD_PPN")}
                </Box>
                <Box width="100%" maxWidth="485px">
                  <NumberInput
                    id="total_line_vat_amount"
                    name="total_line_vat_amount"
                    onChange={(_, value) => handleNumberTypeChange("total_line_vat_amount", value)}
                    min={0}
                    max={Number.POSITIVE_INFINITY}
                    defaultValue={values.total_line_vat_amount}
                    width="100%"
                    maxWidth="485px"
                    isValidCharacter={(value) => value.match(/^[0-9.,]$/)}
                    format={FormatUtil.formatNumberCurrency}
                    parse={FormatUtil.parseFormattedCurrency}
                    pattern="(-)?([0-9.,]+)?"
                  >
                    <NumberInputField />
                  </NumberInput>
                  {errors.total_line_vat_amount && <ValidationError text={errors.total_line_vat_amount} />}
                </Box>
              </Flex>
              <Flex>
                <Box width="15.5rem" marginTop={2}>
                  {t("BTT:FIELD_TOTAL")}
                </Box>
                <Box width="100%" maxWidth="485px">
                  <NumberInput
                    id="total_line_net_amount"
                    name="total_line_net_amount"
                    value={total}
                    width="100%"
                    maxWidth="485px"
                    min={Number.NEGATIVE_INFINITY}
                    max={Number.POSITIVE_INFINITY}
                    onChange={(v, value) => handleNumberTypeChange("total_line_net_amount", v)}
                    isValidCharacter={(value) => value.match(/^[0-9.,-]$/)}
                    format={FormatUtil.formatNumberCurrency}
                    parse={FormatUtil.parseFormattedCurrency}
                    pattern="(-)?([0-9.,]+)?"
                  >
                    <NumberInputField />
                  </NumberInput>
                  {errorTotal ? <ValidationError text={errorTotal} /> : null}
                </Box>
              </Flex>
            </Stack>
            <Flex justifyContent="end" marginBottom={6} paddingX={4}>
              <ButtonPrimary type="submit" text={t("COMMON:BUTTON_SAVE")} />
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalDetailItem;
