import commonEn from "../locale/common/en.json";
import commonId from "../locale/common/id.json";
import accountEn from "../locale/main/account/en.json";
import accountId from "../locale/main/account/id.json";
import AS2LogEn from "../locale/main/AS2Log/en.json";
import AS2LogId from "../locale/main/AS2Log/id.json";
import auditTrailEn from "../locale/main/auditTrail/en.json";
import auditTrailId from "../locale/main/auditTrail/id.json";
import customerEn from "../locale/main/customer/en.json";
import customerId from "../locale/main/customer/id.json";
import dataGroupEn from "../locale/main/dataGroup/en.json";
import dataGroupId from "../locale/main/dataGroup/id.json";
import distributorEn from "../locale/main/distributor/en.json";
import distributorId from "../locale/main/distributor/id.json";
import homeEn from "../locale/main/home/en.json";
import homeId from "../locale/main/home/id.json";
import roleEn from "../locale/main/role/en.json";
import roleId from "../locale/main/role/id.json";
import roleAdminEn from "../locale/main/roleAdmin/en.json";
import roleAdminId from "../locale/main/roleAdmin/id.json";
import roleUserEn from "../locale/main/roleUser/en.json";
import roleUserId from "../locale/main/roleUser/id.json";
import SFTPAS2En from "../locale/main/sftpas2/en.json";
import SFTPAS2Id from "../locale/main/sftpas2/id.json";
import connectionEn from "../locale/main/connection/en.json";
import connectionId from "../locale/main/connection/id.json";
import taxEn from "../locale/main/tax/en.json";
import taxId from "../locale/main/tax/id.json";
import uploadLogEn from "../locale/main/uploadLog/en.json";
import uploadLogId from "../locale/main/uploadLog/id.json";
import userEn from "../locale/main/user/en.json";
import userId from "../locale/main/user/id.json";
import userActivityEn from "../locale/main/userActivity/en.json";
import userActivityId from "../locale/main/userActivity/id.json";
import changePasswordEn from "../locale/main/changePassword/en.json";
import changePasswordId from "../locale/main/changePassword/id.json";
import requestActivationCodeEn from "../locale/main/requestActivationCode/en.json";
import requestActivationCodeId from "../locale/main/requestActivationCode/id.json";
import changeProfileEn from "../locale/main/changeProfile/en.json";
import changeProfileId from "../locale/main/changeProfile/id.json";
import warehouseEn from "../locale/main/warehouse/en.json";
import warehouseId from "../locale/main/warehouse/id.json";

/// user seller

// product
import productPrincipalEn from "../locale/main/productPrincipal/en.json";
import productPrincipalId from "../locale/main/productPrincipal/id.json";
import productCustomerEn from "../locale/main/productCustomer/en.json";
import productCustomerId from "../locale/main/productCustomer/id.json";
import productCategoryEn from "../locale/main/productCategory/en.json";
import productCategoryId from "../locale/main/productCategory/id.json";

// Mapping
import productMappingHOEn from "../locale/main/productMappingHO/en.json";
import productMappingHOId from "../locale/main/productMappingHO/id.json";
import productMappingEn from "../locale/main/productMapping/en.json";
import productMappingId from "../locale/main/productMapping/id.json";
import bklMappingEn from "../locale/main/bklMapping/en.json";
import bklMappingId from "../locale/main/bklMapping/id.json";
import productBKLMappingEn from "../locale/main/productBKLMapping/en.json";
import productBKLMappingId from "../locale/main/productBKLMapping/id.json";
import productCategoryMappingEn from "../locale/main/productCategoryMapping/en.json";
import productCategoryMappingId from "../locale/main/productCategoryMapping/id.json";
import productSubtituteEn from "../locale/main/productSubtitute/en.json";
import productSubtituteId from "../locale/main/productSubtitute/id.json";

// Report 
import reportPOEn from "../locale/main/reportPO/en.json";
import reportPOId from "../locale/main/reportPO/id.json";
import reportPoVsSoEn from "../locale/main/reportPOvsSO/en.json";
import reportPoVsSoId from "../locale/main/reportPOvsSO/id.json";
import reportPoDetailEn from "../locale/main/reportPODetail/en.json";
import reportPoDetailId from "../locale/main/reportPODetail/id.json";

//Transaction
import ImportSOEn from "../locale/main/importSO/en.json";
import ImportSOId from "../locale/main/importSO/id.json";
import proccessApprovePoEn from "../locale/main/proccessApprovePo/en.json";
import proccessApprovePoId from "../locale/main/proccessApprovePo/id.json";
import bklSummaryEn from "../locale/main/bklSummary/en.json";
import bklSummaryId from "../locale/main/bklSummary/id.json";

// Monitoring

import MonitoringListProccessPOEn from "../locale/main/monitoringListProccessPo/en.json";
import MonitoringListProccessPOId from "../locale/main/monitoringListProccessPo/id.json";

/// user buyer
// master - Store
import masterStoreEn from "../locale/main/masterStore/en.json";
import masterStoreId from "../locale/main/masterStore/id.json";
//process - processed
import processedEn from "../locale/main/processedPO/en.json";
import processedId from "../locale/main/processedPO/id.json"
//process - reprocessed
import reprocessedEn from "../locale/main/reprocessedPO/en.json";
import reprocessedId from "../locale/main/reprocessedPO/id.json"
//  store supplier connection
import supplierConnEn from "../locale/main/storeSupplierConnection/en.json"
import supplierConnId from "../locale/main/storeSupplierConnection/id.json"
// notification
import notificationEn from "../locale/main/notification/en.json"
import notificationId from "../locale/main/notification/id.json"
// report list connetion
import listConnectionEn from "../locale/main/reportListConnection/en.json"
import listConnectionId from "../locale/main/reportListConnection/id.json"
//monitoring list PO
import listProcessPOEn from "../locale/main/monitoringListPO/en.json"
import listProcessPOId from "../locale/main/monitoringListPO/id.json"
// monitoringPO
import monitoringPOEn from "../locale/main/monitoringPO/en.json"
import monitoringPOId from "../locale/main/monitoringPO/id.json"
// Monitoring Unmapped Product
import unmappedProductEn from "../locale/main/monitoringUnmappedProduct/en.json"
import unmappedProductId from "../locale/main/monitoringUnmappedProduct/id.json"
// Monitoring Unmapped Store
import unmappedStoreEn from "../locale/main/monitoringUnmappedStore/en.json"
import unmappedStoreId from "../locale/main/monitoringUnmappedStore/id.json"
// dashbaord
import dashboardEn from "../locale/main/dashboard/en.json"
import dashboardId from "../locale/main/dashboard/id.json"
// btt
import bttEn from "../locale/main/btt/en.json";
import bttId from "../locale/main/btt/id.json";



export const resources = {
  en: {
    COMMON: commonEn,
    HOME: homeEn,
    ACCOUNT: accountEn,
    CUSTOMER: customerEn,
    USER: userEn,
    DATA_GROUP: dataGroupEn,
    ROLE:roleEn,
    ROLE_ADMIN: roleAdminEn,
    ROLE_USER: roleUserEn,
    TAX: taxEn,
    USER_ACTIVITY: userActivityEn,
    AUDIT_TRAIL: auditTrailEn,
    CONNECTION : connectionEn,
    UPLOAD_LOG: uploadLogEn,
    SFTP_AS2_LOG: SFTPAS2En,
    CHANGE_PASSWORD: changePasswordEn,
    CHANGE_PROFILE: changeProfileEn,
    REQUEST_ACTIVATION_CODE : requestActivationCodeEn,

    // user seller
    PRODUCT_PRINCIPAL : productPrincipalEn,
    PRODUCT_CUSTOMER : productCustomerEn,
    PRODUCT_CATEGORY : productCategoryEn,

    PRODUCT_MAPPING_HO : productMappingHOEn,
    PRODUCT_MAPPING : productMappingEn,
    BKL_MAPPING : bklMappingEn,
    PRODUCT_BKL_MAPPING : productBKLMappingEn,
    PRODUCT_CATEGORY_MAPPING : productCategoryMappingEn,
    PRODUCT_SUBTITUTE : productSubtituteEn,
    DISTRIBUTOR: distributorEn,
    WAREHOUSE : warehouseEn,
    REPORT_PO : reportPOEn,
    REPORT_PO_VS_SO : reportPoVsSoEn,
    REPORT_PO_DETAIL : reportPoDetailEn,
    IMPORT_SO : ImportSOEn,
    MONITORING_LIST_PROCCESS_PO : MonitoringListProccessPOEn,
    PROCCESS_APPROVE_PO : proccessApprovePoEn,
    BKL_SUMMARY : bklSummaryEn,
    
    
    // user buyer
    STORE : masterStoreEn,
    PROCESSED: processedEn,
    REPROCESSED: reprocessedEn,
    STORE_SUPPLIER_CONNECTION: supplierConnEn,
    NOTIFICATION: notificationEn,
    REPORT_LIST_CONNECTION: listConnectionEn,
    MONITORING_LIST_PO: listProcessPOEn,
    MONITORING_PO: monitoringPOEn,
    UNMAPPED_PRODUCT: unmappedProductEn,
    UNMAPPED_STORE: unmappedStoreEn,

    DASHBOARD : dashboardEn,
    BTT: bttEn
  },
  id: {
    COMMON: commonId,
    HOME: homeId,
    ACCOUNT: accountId,
    CUSTOMER: customerId,
    USER: userId,
    DATA_GROUP: dataGroupId,
    ROLE:roleId,
    ROLE_ADMIN: roleAdminId,
    ROLE_USER: roleUserId,
    TAX: taxId,
    USER_ACTIVITY: userActivityId,
    AUDIT_TRAIL: auditTrailId,
    CONNECTION : connectionId,
    UPLOAD_LOG: uploadLogId,
    SFTP_AS2_LOG: SFTPAS2Id,
    CHANGE_PASSWORD : changePasswordId,
    CHANGE_PROFILE :changeProfileId,
    REQUEST_ACTIVATION_CODE : requestActivationCodeId,


    //user seller
    PRODUCT_PRINCIPAL : productPrincipalId,
    PRODUCT_CUSTOMER :productCustomerId,
    PRODUCT_CATEGORY : productCategoryId,

    PRODUCT_MAPPING_HO : productMappingHOId,
    PRODUCT_MAPPING : productMappingId,
    BKL_MAPPING : bklMappingId,
    PRODUCT_BKL_MAPPING : productBKLMappingId,
    PRODUCT_CATEGORY_MAPPING : productCategoryMappingId,
    PRODUCT_SUBTITUTE : productSubtituteId,
    DISTRIBUTOR: distributorId,
    WAREHOUSE : warehouseId,
    REPORT_PO : reportPOId,
    REPORT_PO_VS_SO : reportPoVsSoId,
    REPORT_PO_DETAIL : reportPoDetailId,
    IMPORT_SO : ImportSOId,
    MONITORING_LIST_PROCCESS_PO : MonitoringListProccessPOId,
    PROCCESS_APPROVE_PO : proccessApprovePoId,
    BKL_SUMMARY : bklSummaryId,

    // user buyer
    STORE : masterStoreId,
    PROCESSED: processedId,
    REPROCESSED: reprocessedId,
    STORE_SUPPLIER_CONNECTION: supplierConnId,
    NOTIFICATION: notificationId,
    REPORT_LIST_CONNECTION: listConnectionId,
    MONITORING_LIST_PO: listProcessPOId,
    MONITORING_PO: monitoringPOId,
    UNMAPPED_PRODUCT: unmappedProductId,
    UNMAPPED_STORE: unmappedStoreId,
    DASHBOARD : dashboardId,

    BTT: bttId
  },
};
