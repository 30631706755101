
export const MIME = {
    acceptImageType: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg', '.jpeg'],
      'image/gif': ['.gif']
    },
    acceptImageLogoType: {
      'image/jpg': ['.jpg', '.jpeg'],
    },
    mimeOctetStream: 'application/octet-stream'
  }