import { format } from 'date-fns';
import { id, enUS } from 'date-fns/locale';
import i18n from '../config/i18n';

const AVAILABLE_LOCALE = {
  'id-ID': id,
  'en-US': enUS
}

export const FORMAT = {
  DEFAULT_DATE: 'dd/MM/yyyy',
  DEFAULT_DATE_XLSX: 'dd_MM_yyyy',
  DEFAULT_DATE_API: 'yyyy-MM-dd',
  DATE_TIME: 'dd-MM-yyyy HH:mm:ss',
  DATE_FORMAT :  'YYYY/MM/DD',
  DEFAULT_TIME: 'HH:mm:ss',
  DATE_FILE_NAMING: 'yyyy-MM-dd',
  TIME_FILE_NAMING: 'HH-mm',
  BTT_PO_DATE: "dd.MM.yyyy (HH:mm:ss)"
  // DATE_RANGE: 'yyyy-MM-ddTHH:mm:ss'
}

/**
 * Funtion that format Date type to string
 * @param {number | string | Date} date - Date Instance or date iso string or date in unix milliseconds
 * @param {} stringFormat - String format, reference from date-fns (https://date-fns.org/v2.29.3/docs/format)
 * @returns 
 */
const formatDate = (date, stringFormat = FORMAT.DEFAULT_DATE, defaultValue = '') => {
  if (!date) return defaultValue;
  const locale = AVAILABLE_LOCALE[i18n.language];
  if (typeof date === 'string') {
    if (date === '0001-01-01' || date.includes('0001-01-01')) return defaultValue;
    return format(new Date(date), stringFormat, { locale });
  }
  
  try {
    return format(date, stringFormat, { locale });
  } catch (error) {
    return '';
  }
}

const UTCToLocalDate = (dateString = "") => {
  if (!dateString) return null;
  const currentDateString = dateString.replace('Z', '');
  const currentDate = new Date(currentDateString);
  return currentDate;
}

const convertLocalDateString = (date) => {
  if (!date) date = new Date();
  return format(date, "yyyy-MM-dd'T'HH:mm:ss")
}

const formatUTCToLocalDate = (date, stringFormat = FORMAT.DEFAULT_DATE, defaultValue = '') => {
  if (!date) return defaultValue;
  
  return formatDate(UTCToLocalDate(date), stringFormat, defaultValue);
}

const DateUtil = {
  formatDate,
  UTCToLocalDate,
  formatUTCToLocalDate,
  convertLocalDateString
}

export default DateUtil;