import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Input,
  Stack,
  Text,
  Textarea,
  list,
} from "@chakra-ui/react";
import HeadTitle from "../../../../../components/HeadTitle";
import { useTranslation } from "react-i18next";
import { PRIMARY_COLOR } from "../../../../../constant/propertiesConstant";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import Accordion from "../../../../../components/accordion/Accordion";
import styles from "./modules/CreateBTT.module.css";
import { useLocation, useHistory } from "react-router-dom";
import { getListDetailBTT, saveFileBTT } from "../../../../../services/main/bttReducer";
import { Pagination, Select, Table } from "antd";
import ModalDetailItem from "./components/ModalDetailItem";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import { DeleteIcon, DeleteSuccess, EditIcon, SavedSuccess, UnSuccess } from "../../../../../components/icons";
import { ID_BTT } from "../../../../../constant/idConstant";
import { convertStringContentToBlob, downloadCSVStringContent } from "../../../../../services/util";
import Spinner from "../../../../../components/spinner/Spinner";
import ModalConfirm from "../../../../../components/ModalConfirm";
import DateUtil, { FORMAT } from "../../../../../utils/dateUtil";
import ModalCustom from "../../../../../components/ModalCustom";
import InputDateTimePicker from "../../../../../components/datePicker/InputDateTimePicker";
import { PAGE, defaulPageLimitOption } from "../../../../../services/serviceUtil";
import { REGEX_CONSTANT } from "../../../../../constant/regexConstant";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";

const StaticData = {
  NAMA_NPWP : "PT SURIATAMA MAHKOTA KENCANA",
  NO_NPWP : "18506667123000",
  ADDRESS_NPWP : "JL.TAHI BONAR SIMATUPANG SUZUYA DC SUNGGAL MEDAN SUNGGAL KOTA MEDAN SUMATERA UTARA 20128",
  HEADER: ["TglDN", "INVSYS", "NmNpWp", "NoNpwp", "AddNPWP", "Barcode", "NmBrg", "QTY", "UOM", "HARGA", "DISC1", "PPN", "TOTAL"],

}

const generateCSVFilename = () => {
  const currentDate = new Date();
  const dateString = DateUtil.formatDate(currentDate, FORMAT.DATE_FILE_NAMING);
  const timeString = DateUtil.formatDate(currentDate, FORMAT.TIME_FILE_NAMING)
  return `Suzuya_BTT_${dateString}_${timeString}.csv`;
}

const CreateBTT = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { is_loading } = useSelector((state) => state.btt);
  const [listDetail, setListDetail] = useState([]);
  const [modalEdit, setModalEdit] = useState({ data: null, isOpen: false });
  const [modalDelete, setModalDelete] = useState({ data: { id: null, index: null }, isOpen: false });
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalFailed, setModalFailed] = useState({ isOpen: false, message: '' });

  const [selectedDetailKey, setSelectedDetailKey] = useState(null);
  const [listItemDetail, setListItemDetail] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10, total: 0 });
  const [panelError, setPanelError] = useState({ isError: false, message: "" });

  const fetchListDetail = useCallback(
    (listId) => {
      if (listId) {
        dispatch(getListDetailBTT(listId)).then((res) => {
          if (res.payload.ok) setListDetail(res.payload.response);
        });
      }
    },
    [dispatch]
  );

  const handleOpenModalEditItem = useCallback(
    (selectedPOId, itemIndex, data) => {
      setModalEdit({
        data: { ...data, index: itemIndex },
        isOpen: true,
      });
    },
    []
  );

  const handleOnSubmitModalEditItem = useCallback(
    (data) => {
      const { page, pageSize } = pagination;
      const { id, index: indexItem } = data;
      const currentIndex = (page - 1) * pageSize + indexItem;

      const copiedListDetail = listDetail.map((value) => ({
        ...value,
        list_detail: [...value.list_detail],
      }));
      const findSelectedPOIndex = copiedListDetail.findIndex(
        (value) => value.id === id
      );

      if (findSelectedPOIndex >= 0) {
        const listItem = [...copiedListDetail[findSelectedPOIndex].list_detail];
        listItem[currentIndex] = { ...data };
        copiedListDetail[findSelectedPOIndex].list_detail = listItem;
        setListDetail([...copiedListDetail]);
      }

      setModalEdit((prev) => ({
        isOpen: false,
        data: null,
      }));
    },
    [listDetail, pagination]
  );

  const handleOnCloseModalEditItem = useCallback(
    () => setModalEdit({ data: null, isOpen: false }),
    []
  );
 
  const handleOpenModalDeleteItem = useCallback(
    (selectedPOId, itemIndex) => {
      setModalDelete({
        data: { id: Number(selectedPOId), index: itemIndex },
        isOpen: true,
      });
    },
    []
  );

  const handleOnSubmitDeleteItem = useCallback(() => {
    const { id, index: itemIndex } = modalDelete.data;
    const { page, pageSize } = pagination;
    const currentIndex = (page - 1) * pageSize + itemIndex;

    const copiedListDetail = listDetail.map((value) => ({
      ...value,
      list_detail: [...value.list_detail],
    }));
    const findSelectedPOIndex = copiedListDetail.findIndex((value) => value.id === id);
    if (findSelectedPOIndex >= 0) {
      const listItem = copiedListDetail[findSelectedPOIndex].list_detail.filter((_, index) => index !== currentIndex);
      copiedListDetail[findSelectedPOIndex].list_detail = listItem;
      setListDetail([...copiedListDetail]);
      setPagination((prev) => ({
         ...prev, 
         total: listItem.length,
         page: listItem.length <= prev.pageSize * (page - 1 ) ? prev.page - 1 : prev.page
        }));
    }

    setModalDelete(({
      data: { id: null, index: null },
      isOpen: false
    }));
  }, [listDetail, modalDelete.data, pagination]);

  const handleOnCloseModalDeleteItem = useCallback(() => {
    setModalDelete(({
      data: { id: null, index: null },
      isOpen: false
    }));
  }, []);

  const handleOnCloseModalSuccess = useCallback(() => {
    setModalSuccess(false);
    history.replace(ROUTES_USER_SELLER.BTT.LIST)
  }, [history]);

  const handleOnChangeEachPOItem = useCallback((namespace, selectedId, value) => {
    let newListDetail = [];
    let panelErrorMessage = "";
    switch (namespace) {
      case "po_date":
        newListDetail = listDetail.map((item) => {
    
          if (item.id === selectedId) {
            const newValue = { ...item };
            newValue[namespace] = DateUtil.convertLocalDateString(value);
            return newValue;
          } 
          return item;
        });
        break;
      case "po_number":
      if (value && value.includes(',')) {
        panelErrorMessage = t("BTT:ERROR_CANNOT_HAVE_COMMA", { field: t("BTT:FIELD_INVOICE_NUMBER") });
      }
        
        newListDetail = listDetail.map((item) => {

          if (item.id === selectedId) {
            const newValue = { ...item };
            newValue[namespace] = value;
            return newValue;
          } 
    
          return item;
        });
        break;
      default:
        panelErrorMessage = "";
        newListDetail = listDetail.map((item) => {
          if (item.id === selectedId) {
            const newValue = { ...item };
            newValue[namespace] = value;
            return newValue;
          } 
    
          return item;
        });
        break;
    }
    
    setPanelError({ isError: Boolean(panelErrorMessage), message: panelErrorMessage });
    setListDetail(newListDetail);
  }, [listDetail, t]);

  const handleOnChangePanel = useCallback((panelId) => {

    if (panelError.isError) return;

    setSelectedDetailKey(panelId);
    const selectedPOPanelIndex = listDetail.findIndex((value) => value.id === Number(panelId));
    
    if (selectedPOPanelIndex > -1) {
      const listItem = listDetail[selectedPOPanelIndex].list_detail;
      setListItemDetail(listItem);
      setPagination({ page: 1, pageSize: PAGE.DEFAULT_PAGE_LIMIT, total: listItem.length });
    }

  }, [listDetail, panelError]);

  const populateData = useCallback((listDetail = []) => {
    const csvRow = [StaticData.HEADER];

    if (Array.isArray(listDetail) && listDetail.length > 0) {
      for (let indexList = 0; indexList < listDetail.length; indexList++) {
        const { po_number, po_date, list_detail } = listDetail[indexList];

        for (let indexItem = 0; indexItem < list_detail.length; indexItem++) {
          const { 
            barcode,
            product_name,
            qty_1,
            uom_1,
            price_uom_1,
            total_line_discount,
            total_line_vat_amount,
            total_line_net_amount,
           } = list_detail[indexItem];

          csvRow.push([
            DateUtil.formatDate(po_date, FORMAT.BTT_PO_DATE),
            po_number,
            StaticData.NAMA_NPWP,
            StaticData.NO_NPWP,
            StaticData.ADDRESS_NPWP,
            barcode,
            product_name,
            qty_1,
            uom_1,
            price_uom_1,
            total_line_discount,
            total_line_vat_amount,
            total_line_net_amount
          ]);
        }
      }
    }

    return csvRow;
  }, []); 

  const generateCSV = useCallback((arrayOfString = [[]]) => {
    let csvString = arrayOfString.reduce((prev, current, index) => {
      const rowString = current.join(',');
      prev += index < arrayOfString.length ? rowString + '\n' : rowString
      return prev;
    }, String());

    return csvString;
  }, []);

  const handleDownloadBTT = useCallback(() => {
    const csvRow = populateData(listDetail);
    const csvString = generateCSV(csvRow);
    
    const blobContent = convertStringContentToBlob(csvString);
    const fileName = generateCSVFilename();
    const bttFile = new File([blobContent], fileName, { type: blobContent.type });
    const formData = new FormData();

    const listId = listDetail.map((item) => item.id);
    formData.append('content', JSON.stringify({ id: listId }))
    formData.append('file', bttFile);

    dispatch(saveFileBTT(formData)).then((res) => {
      
      if (res.payload.ok) {
        downloadCSVStringContent(csvString, fileName);
        setModalSuccess(true);
      } else {
        setModalFailed({ isOpen: true, message: res.payload?.response ?? t("COMMON:ERROR_NETWORK") });
      }
    });

  }, [populateData, listDetail, generateCSV, dispatch, t]);

  const hasAllInvoiceNumber = useMemo(() => {
    let isHasAll = true;
    for (let i = 0; i < listDetail.length; i++) {
      if (!listDetail[i].po_number) {
        isHasAll = false;
        break;
      }
    }

    return isHasAll;
  }, [listDetail]);

  const columns = useMemo(
    () => [
      {
        dataIndex: "barcode",
        title: (
          <Box
            display="flex"
            alignItems="center"
            cursor="pointer"
            fontWeight={700}
          >
            {t("BTT:FIELD_BARCODE")}
          </Box>
        ),
      },
      {
        dataIndex: "product_name",
        title: (
          <Box
            display="flex"
            alignItems="center"
            cursor="pointer"
            fontWeight={700}
          >
            {t("BTT:FIELD_PRODUCT_NAME")}
          </Box>
        ),
      },
      {
        dataIndex: "qty_1",
        title: (
          <Box
            display="flex"
            alignItems="center"
            cursor="pointer"
            fontWeight={700}
          >
            {t("BTT:FIELD_QTY")}
          </Box>
        ),
      },
      {
        title: (
          <Box
            display="flex"
            alignItems="center"
            cursor="pointer"
            fontWeight={700}
          >
            {t("BTT:FIELD_UOM")}
          </Box>
        ),
        dataIndex: "uom_1",
      },
      {
        title: (
          <Box
            display="flex"
            alignItems="center"
            cursor="pointer"
            fontWeight={700}
            justifyContent="center"
          >
            {t("COMMON:ACTION")}
          </Box>
        ),
        dataIndex: "id",
        render: (value, original, index) => (
          <Flex justifyContent="center">
            <ButtonCustom
              id={`${ID_BTT.CREATE.BTN_EDIT}_${value}_${index}`}
              size="sm"
              text={<EditIcon />}
              backgroundcolor="transparent"
              onClick={() =>
                handleOpenModalEditItem(selectedDetailKey, index, original)
              }
            />
            <ButtonCustom
              size="sm"
              text={<DeleteIcon />}
              backgroundcolor="transparent"
              id={`${ID_BTT.CREATE.BTN_DELETE}_${value}_${index}`}
              onClick={() =>
                handleOpenModalDeleteItem(selectedDetailKey, index)}
            />
          </Flex>
        ),
      },
    ],
    [t, handleOpenModalEditItem, handleOpenModalDeleteItem, selectedDetailKey]
  );

  const generatePaginatedList = useCallback((listItemDetail) => {
    const { page, pageSize } = pagination;
    return listItemDetail.slice((page - 1) * pageSize, page * pageSize);
  }, [pagination]);

  useEffect(() => {
    fetchListDetail(location.state);
  }, [location, fetchListDetail]);

  return (
    <>
      <Box padding={4}>
        <HeadTitle
          title={t("BTT:TITLE")}
          subtitle={
            <>
              <span style={{ color: PRIMARY_COLOR }}>
                {t("BTT:BREADCRUMS.HOME")}
              </span>{" "}
              -{" "}
              <span style={{ color: PRIMARY_COLOR }}>
                {t("BTT:BREADCRUMS.TRANSACTION")}
              </span>{" "}
              -{" "}
              <span style={{ color: PRIMARY_COLOR }}>
                {t("BTT:BREADCRUMS.BTT")}
              </span>{" "}
              - {t("BTT:BREADCRUMS.CREATE_BTT")}
            </>
          }
          button={
            <ButtonPrimary
              text={t("BTT:DOWNLOAD_BTT")}
              onClick={handleDownloadBTT}
              isDisabled={!hasAllInvoiceNumber || panelError.isError}
            />
          }
        />
        <Box
          backgroundColor="white"
          padding={4}
          marginTop={6}
          marginBottom={6}
          borderRadius={10}
        >
          <Stack gap={2}>
            <Flex>
              <Box width="10rem" marginTop={2}>
                {t("BTT:NPWP_NAME")}
              </Box>
              <Input
                type="text"
                maxWidth="485px"
                value={StaticData.NAMA_NPWP}
                disabled
              />
            </Flex>
            <Flex>
              <Box width="10rem" marginTop={2}>
                {t("BTT:NPWP_NUMBER")}
              </Box>
              <Input
                type="text"
                maxWidth="485px"
                value={StaticData.NO_NPWP}
                disabled
              />
            </Flex>
            <Flex>
              <Box width="10rem" marginTop={2}>
                {t("BTT:NPWP_ADDRESS")}
              </Box>
              <Textarea
                type="text"
                maxWidth="485px"
                maxHeight="60px"
                value={StaticData.ADDRESS_NPWP}
                disabled
              />
            </Flex>
          </Stack>
        </Box>

        <Box>
          <HeadTitle title={t("BTT:LIST_PO")} />
          {is_loading ? (
            <Box marginTop={2}>
              <Spinner />
            </Box>
          ) : (
            <Box minHeight="300px">
              <Accordion
                activeKey={selectedDetailKey}
                onChange={handleOnChangePanel}
              >
                {Array.isArray(listDetail) &&
                  listDetail.map((item) => (
                    <div
                      key={item.id}
                      panelid={`panel_${item.id}`}
                      header={
                        <div id={`panel_header_${item.id}`}>
                          <Text fontSize="xs">{item.store_name}</Text>
                          <Text fontWeight={700} fontSize="md" maxWidth="520px" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                            {item.po_number}
                          </Text>
                        </div>
                      }
                    >
                      <Box>
                        <Stack gap={2} marginBottom={4}>
                          <Flex>
                            <Box width="10rem" marginTop={2}>
                              {t("BTT:FIELD_INVOICE_DATE")}
                            </Box>
                              <Box width="100%" maxWidth="485px">
                                <InputDateTimePicker 
                                  value={DateUtil.UTCToLocalDate(item.po_date)} 
                                  onChange={(date) => handleOnChangeEachPOItem("po_date", item.id, date)}
                                  inputFormat={FORMAT.BTT_PO_DATE}
                                />
                              </Box>
                          </Flex>
                          <Flex>
                            <Box width="10rem" marginTop={2}>
                              {t("BTT:FIELD_INVOICE_NUMBER")}
                            </Box>
                            <Box width="100%" maxWidth="485px">
                              <Input
                                defaultValue={item.po_number}
                                type="text"
                                maxWidth="485px"
                                onChange={(e) => handleOnChangeEachPOItem("po_number", item.id, e.target.value)}
                              />
                              {panelError.isError && <ValidationError maxWidth="400px" text={panelError.message} />}
                            </Box>
                          </Flex>
                        </Stack>
                        <Table
                          dataSource={generatePaginatedList(item.list_detail)}
                          columns={columns}
                          pagination={false}
                          size="small"
                          footer={() => (
                            <Box
                              backgroundColor="#E5EBF1"
                              display="flex"
                              alignItems="center"
                              justifyContent="end"
                            >
                              <Box display="flex" alignItems="center" marginX={2}>
                                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                                <Select
                                  border="1px solid"
                                  size="sm"
                                  width={20}
                                  _focus={{ outline: "none" }}
                                  value={pagination.pageSize}
                                  disabled={is_loading}
                                  onChange={(value) => setPagination((prev) => ({ ...prev, pageSize: value }))}
                                  options={defaulPageLimitOption} />
                              </Box>
                              <Box>
                                <Pagination
                                  size="small"
                                  current={pagination.page}
                                  total={pagination.total}
                                  onChange={(value) => setPagination((prev) => ({ ...prev, page: value }))}
                                  showSizeChanger={false}
                                  pageSize={pagination.pageSize}
                                  disabled={is_loading}
                                />
                              </Box>
                            </Box>
                          )}
                        />
                      </Box>
                    </div>
                  ))}
              </Accordion>
            </Box>
          )}
        </Box>
      </Box>
      <ModalDetailItem
        data={modalEdit.data}
        isOpen={modalEdit.isOpen}
        onSubmit={handleOnSubmitModalEditItem}
        onCancel={handleOnCloseModalEditItem}
      />
      <ModalConfirm
        messageModal={t("COMMON:DELETE_TITLE")}
        messageConfirm={t("COMMON:DELETE_CONFIRM")}
        onClose={handleOnCloseModalDeleteItem}
        onOk={handleOnSubmitDeleteItem}
        isOpen={modalDelete.isOpen}
        // idYes={ID_USER.DETAIL.BTN_YES}
        // idNo={ID_USER.DETAIL.BTN_NO}
      />
      <ModalCustom
        message={t("BTT:SUCCESS_MESSAGE")}
        isOpen={modalSuccess}
        onOk={handleOnCloseModalSuccess}
        icon={<SavedSuccess />}
      />
      <ModalCustom
        message={modalFailed.message}
        isOpen={modalFailed.isOpen}
        onOk={() => setModalFailed({ isOpen: false, message: ''})}
        icon={<DeleteSuccess />}
      />
    </>
  );
};

export default CreateBTT;
