import moment from "moment";
import { useEffect, useRef } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as baseApi from "../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../constant/apiConstant";
export const addMinutes = (dateTime, minutes) => {
  if (dateTime || minutes) {
    return moment(dateTime).add(minutes, "m");
  } else {
    return dateTime;
  }
};
export const PAGE = {
  DEFAULT_PAGE_LIMIT: 10,
  DEFAULT_PAGE: 1,
  DEFAULT_PAGE_LIMIT_OPTION: [10, 25, 50, 100],
};

export const defaulPageLimitOption = Object.freeze(PAGE.DEFAULT_PAGE_LIMIT_OPTION.map((value) => ({ value, label: value })))

export const useWithInstanceListAndInitiate = (
  {
    isOption = false,
    initialFilter,
    skipFirstRun = false,
    pathInitiate = "",
    pathList = "",
  } = { isOption: false, initialFilter: "", skipFirstRun: false }
) => {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(PAGE.DEFAULT_PAGE_LIMIT);
  const [order, setOrder] = useState("");
  const [filter, setFilter] = useState("");
  const [error, setError] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initiate, setInitiate] = useState(null);
  const [_isOption, _setIsOption] = useState(isOption);
  const [_isEndPage, _setIsEndPage] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const isFirstRun = useRef(true);

  const resetAllState = useCallback(() => {
    setList([]);
    setLimit(PAGE.DEFAULT_PAGE_LIMIT);
    setPage(1);
    setOrder("");
    setFilter("");
    setError(null);
    setHasError(false);
    setIsLoading(false);
    setInitiate(null);
    _setIsOption(isOption);
    _setIsEndPage(false);
  }, []);

  const fetchInitiate = useCallback(async () => {
    _setIsEndPage(false);
    setError(null);
    setIsLoading(true);
    setHasError(false);
    setInitiate(null);
    if (!pathInitiate) return;
    try {
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/${pathInitiate}`;
      const { data } = await baseApi.doGet(url, token, { filter });
      const response = {
        content: data?.nexsoft?.payload?.data?.content,
        status: data?.nexsoft?.payload?.status,
      };
      setInitiate(response);
    } catch (err) {
      setHasError(true);
      setError(err?.response.payload.response.data.nexsoft.payload.status);
      // setError(formatError(err, formatErrorResponse));
    } finally {
      setIsLoading(false);
    }
  }, [filter, token]);

  const fetchList = useCallback(async () => {
    _setIsEndPage(false);
    setError(null);
    setIsLoading(true);
    setHasError(false);
    try {
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/${pathList}`;
      const { data } = await baseApi.doGet(url, token, {
        filter,
        page,
        limit,
        order,
      });
      let list = data?.nexsoft?.payload?.data?.content ?? [];
      if (!list & _isOption) _setIsEndPage(true);
      if (_isOption) {
        setList((prev) => [...prev, ...list]);
      } else {
        setList(list);
      }
    } catch (err) {
      setHasError(true);
      setError(err?.response.payload.response.data.nexsoft.payload.status);
    } finally {
      setIsLoading(false);
    }
  }, [page, limit, filter, order, _isOption]);

  const _setFilter = useCallback(
    (filters) => {
      if (filters !== filter) {
        setList([]);
        setPage(1);
        setFilter(filters);
      }
    },
    [filter]
  );

  const _setNextPage = useCallback(() => {
    if (_isOption && _isEndPage) return;
    setPage((prev) => prev + 1);
  }, []);

  const getAllNoLimit = useCallback(
    async (initialFilter, customPathList) => {
      setIsLoading(true);
      try {
        const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/${
          customPathList ? customPathList : pathList
        }`;
        const noLimitParams = {
          page: -99,
          limit: -99,
          updated_at_start: "2000-01-01T00:00:00Z",
        };

        const { data } = await baseApi.doGet(url, token, {
          filter: filter ?? initialFilter,
          ...noLimitParams,
        });
        let list = data?.nexsoft?.payload?.data?.content ?? [];
        setList(list);
        return list;
      } catch (err) {
        setHasError(true);
        setError(err?.response.payload.response.data.nexsoft.payload.status);
      } finally {
        setIsLoading(false);
      }
    },
    [filter, token, pathList]
  );

  useEffect(() => {
    fetchInitiate();
  }, [fetchInitiate]);

  useEffect(() => {
    if (isFirstRun.current && skipFirstRun) {
      isFirstRun.current = false;
      return;
    }
    fetchList();
  }, [fetchList]);

  return {
    initiate,
    list,
    page,
    limit,
    order,
    filter,
    isLoading,
    hasError,
    error,
    setPage,
    setLimit,
    setFilter: _setFilter,
    setOrder,
    setNextPage: _setNextPage,
    getAllNoLimit,
    resetAllState,
  };
};
