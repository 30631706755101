import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import { Modal, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import BackToList from "../../../../../components/BackToList";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import DropDownCustom from "../../../../../components/DropDownCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  BlueEditIcon,
  DeleteSuccess,
  SavedSuccess,
  UnSuccess,
} from "../../../../../components/icons";
import ModalCustom from "../../../../../components/ModalCustom";
import {
  GRAY_COLOR,
  PRIMARY_COLOR,
} from "../../../../../constant/propertiesConstant";
import {
  ROUTES_USER_BUYER,
  ROUTES_USER_SELLER,
} from "../../../../../constant/routeConstant";
import {
  selectOptFilterDistributorTheme,
  selectOptFilterUserTheme,
} from "../../../../../constant/themeConstant";
import { detailBKLSummary } from "../../../../../services/main/bklSummaryReducer";
import {
  changeWarehouse,
  detailApprovePO,
  getListWarehouse,
} from "../../../../../services/main/proccessApprovePoReducer";
import TransactionDetailProcess from "./DetailTransactionDetailProcess";

const DetailProccessApprovePo = () => {
  const dispatch = useDispatch();
  const [data, setdata] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const { list_warehouse, status } = useSelector((state) => state.proccessApprovePo);

  // warehouse
  const [dataWarehouse, setDataWarehouse] = useState([]);
  const [inputWarehouse, setInputWarehouse] = useState("");
  const [warehouseId, setWarehouseId] = useState(-1);
  const [prevWarehouseId, setPrevSetWarehouseId] = useState({});
  const [pageWarehouse, setPageWarehouse] = useState(1);
  const [limitWarehouse, setLimitWarehouse] = useState(10);
  const [lastPageWarehouse, setLastPageWarehouse] = useState(false);

  /// modal ketika edit warehouse
  const [warehouseIsChanged, setWarehouseIsChanged] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setWarehouseIsChanged(!warehouseIsChanged);
    setModalOpen(false);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    const payload = {
      id: location.state.id,
      warehouse_id: warehouseId,
      updated_at: data?.updated_at,
    };
    dispatch(changeWarehouse(payload)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setWarehouseId({
      value: prevWarehouseId.value,
      label: prevWarehouseId.label,
    });
  };

  const fetchDataWarehouse = () => {
    const page = pageWarehouse;
    const limit = limitWarehouse;
    const params = { page, limit };
    dispatch(getListWarehouse(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;

      if (!resp) {
        setLastPageWarehouse(true);
        return;
      }
      setDataWarehouse([...dataWarehouse, ...resp]);
    });
  };
  const fetchDataWarehouseSearch = () => {
    const page = pageWarehouse;
    const limit = limitWarehouse;
    const filter = `warehouse_name like ${inputWarehouse}`;
    const params =
      inputWarehouse === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListWarehouse(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageWarehouse(1);
      setDataWarehouse(response.payload.response);
      setLastPageWarehouse(false);
    });
  };
  const handleScrollWarehouse = () => {
    if (lastPageWarehouse || status === 'loading') return;
    setPageWarehouse(pageWarehouse + 1);
  };
  const handleSearchWarehouse = (event) => {
    setInputWarehouse(event);
  };

  // warehouse
  useEffect(() => {
    fetchDataWarehouse();
  }, [pageWarehouse]);

  useEffect(() => {
    fetchDataWarehouseSearch();
  }, [inputWarehouse]);
  const fetchData = () => {
    dispatch(detailApprovePO(location.state.id)).then((response) => {
      if (response.payload.ok) {
        setdata(response.payload.response);
        setWarehouseId({
          value: response.payload.response?.warehouse_id,
          label: response?.payload?.response?.deliver_warehouse_name,
        });
        setPrevSetWarehouseId({
          value: response.payload.response?.warehouse_id,
          label: response?.payload?.response?.deliver_warehouse_name,
        });
      } else {
        setdata([]);
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, [warehouseIsChanged]);
  return (
    <Box p={4}>
      <HeadTitle
        title={t("PROCCESS_APPROVE_PO:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("COMMON:FIELD_HOME")}
            </span>
            {" - "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PROCCESS_APPROVE_PO:TRANSACTION")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PROCCESS_APPROVE_PO:FIELD_PO")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PROCCESS_APPROVE_PO:TITLE_LIST")}
            </span>
          </>
        }
        button={
          <>
            <ButtonCustom
              variant="outline"
              colorScheme="blue"
              marginRight={2}
              width="auto"
              icon={<BlueEditIcon />}
              onClick={showModal}
              text={t("PROCCESS_APPROVE_PO:FIELD_WAREHOUSE_CHANGE")}
            />
          </>
        }
      />
      {/* Back To List , Created By ,dll  */}
      <BackToList
        link={ROUTES_USER_SELLER.PROCESS_APPROVE_PO.LIST}
        name={data?.created_by}
        date={moment(data?.created_at).format("DD/MM/YYYY")}
        modifiedDate={moment(data?.updated_at).format("DD/MM/YYYY")}
        modifiedBy={data?.updated_name}
      />
      {/* White Box section */}
      <Box
        backgroundColor="white"
        minHeight="277px"
        padding={4}
        display="flex"
        justifyContent="space-between"
        marginTop={2}
        borderRadius="10px"
      >
        {/* left side */}
        <Box className="column-po">
          {/* PO Number */}
          <Box className="list-po">
            <Box>{t("PROCCESS_APPROVE_PO:FIELD_PO_NUMBER")}</Box>
            <Box>
              <Text>
                <b>{data?.po_number === "" ? "-" : data?.po_number}</b>
              </Text>
            </Box>
          </Box>
          {/* Distributor*/}
          <Box className="list-po">
            <Box width="10rem">
              <Text>{t("PROCCESS_APPROVE_PO:FIELD_DISTRIBUTOR")}</Text>
              <Text>
                <b>
                  {data?.distributor_name === "" ? "-" : data?.distributor_name}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Store*/}
          <Box className="list-po">
            <Box>
              <Text>{t("PROCCESS_APPROVE_PO:FIELD_STORE")}</Text>
            </Box>
            <Text>
              <b>{data?.store_name === "" ? "-" : data?.store_name}</b>
            </Text>
          </Box>
          {/* store Code*/}
          <Box className="list-po">
            <Box>
              <Text>{t("PROCCESS_APPROVE_PO:FIELD_STORE_CODE")}</Text>
            </Box>
            <Text>
              <b>{data?.store_code === "" ? "-" : data?.store_code}</b>
            </Text>
          </Box>
        </Box>
        {/* Middle side */}
        <Box className="column-po">
          {/* Po Date*/}
          <Box className="list-po">
            <Box>
              <Text>{t("PROCCESS_APPROVE_PO:FIELD_PO_DATE")}</Text>
            </Box>
            <Text>
              <b>
                {data?.po_date === ""
                  ? "-"
                  : moment(data?.po_date).format("YYYY-MM-DD")}
              </b>
            </Text>
          </Box>
          {/* Delivery Date*/}
          <Box className="list-po">
            <Box width="10rem">
              <Text>{t("PROCCESS_APPROVE_PO:DELIVERY_DATE")}</Text>
            </Box>
            <Text>
              <b>
                {data?.delivery_date === ""
                  ? "-"
                  : moment(data?.delivery_date).format("YYYY-MM-DD")}
              </b>
            </Text>
          </Box>
          {/* Expired Date*/}
          <Box className="list-po">
            <Box width="10rem">
              <Text>{t("PROCCESS_APPROVE_PO:FIELD_EXPIRED_DATE")}</Text>
            </Box>
            <Text>
              <b>
                {data?.expired_date === ""
                  ? "-"
                  : moment(data?.expired_date).format("YYYY-MM-DD")}
              </b>
            </Text>
          </Box>
          {/* NET Amount PO*/}
          <Box className="list-po">
            <Box width="10rem">
              <Text>{t("PROCCESS_APPROVE_PO:FIELD_NET_AMOUNT_PO")}</Text>
            </Box>
            <Text>
              <b>{data?.po_net_amount === "" ? "-" : data?.po_net_amount}</b>
            </Text>
          </Box>
        </Box>

        {/* Right side */}
        <Box className="column-po">
          {/* NET AMOUNT SO */}
          <Box className="list-po">
            <Box>{t("PROCCESS_APPROVE_PO:FIELD_NET_AMOUNT_SO")}</Box>
            <Box>
              <Text>
                <b>{data?.so_net_amount === "" ? "-" : data?.so_net_amount}</b>
              </Text>
            </Box>
          </Box>
          {/* DO net Amount */}
          <Box className="list-po">
            <Box>{t("PROCCESS_APPROVE_PO:FIELD_NET_AMOUNT_DO")}</Box>
            <Box>
              <Text>
                <b>{data?.do_net_amount === "" ? "-" : data?.do_net_amount}</b>
              </Text>
            </Box>
          </Box>
          {/* Week */}
          <Box className="list-po">
            <Box>{t("PROCCESS_APPROVE_PO:FIELD_WAREHOUSE")}</Box>
            <Box>
              <Text>
                <b>
                  {data?.deliver_warehouse_name === ""
                    ? "-"
                    : data?.deliver_warehouse_name}
                </b>
              </Text>
            </Box>
            {/* di hidden dulu buat DDS Warehousenya */}
            {/* <ButtonCustom
              text={t("PROCCESS_APPROVE_PO:CHANGE_WAREHOUSE")}
              // id="BTN-UserBuyer-Store-UploadFile"
              colorScheme="blue"
              variant="outline"
              width={"147px"}
              height={"32px"}
              fontsize={12}
              icon={<EditIconMini />}
              onClick={showModal}
            /> */}
          </Box>
        </Box>
      </Box>

      {/* Transaction Detail */}
      <TransactionDetailProcess />

      <Modal
        centered
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        footer={null}
      >
        <Box
          justifyContent={"space-between"}
          display={"flex"}
          flexDir={"column"}
          className="my-select-container"
        >
          <Box>
            <HeadTitle
              title={t("PROCCESS_APPROVE_PO:FIELD_WAREHOUSE_CHANGE")}
            />
          </Box>
          <Box display={"flex"} pt={4}>
            <Box mr={10} width={"100px"} mt={-1} fontWeight="500">
              <Text color="grey">
                {t("PROCCESS_APPROVE_PO:FIELD_WAREHOUSE_DELIVER")}
              </Text>
            </Box>
            <Select
              style={selectOptFilterDistributorTheme}
              onChange={(value, opt) => setWarehouseId(value, opt)}
              size="large"
              // placeholder="Gudang"
              value={warehouseId !== -1 ? warehouseId : null}
              onPopupScroll={handleScrollWarehouse}
              fontSize="14px"
              backgroundColor="white"
              showSearch={false}
              textColor={GRAY_COLOR}
              dropdownRender={(menu) => (
                <DropDownCustom
                  menu={menu}
                  search={(e) => handleSearchWarehouse(e.target.value)}
                />
              )}
            >
              {dataWarehouse?.map((value, index) => {
                return (
                  <option key={index.toString()} value={value.id} data={value}>
                    {value.warehouse_code + " - " + value.warehouse_name}
                  </option>
                );
              })}
            </Select>
          </Box>
        </Box>
        <Box pt={10}>
          <Flex gap={"0.5rem"} direction="row-reverse">
            <ButtonPrimary text="Save" width="9.375rem" onClick={handleOk} />
            <ButtonCustom
              colorScheme="blue"
              variant="outline"
              marginRight={2}
              width="9.375rem"
              //   link={ROUTES.ROLE.LIST}
              text="Cancel"
              onClick={handleCancel}
            />
          </Flex>
        </Box>
      </Modal>
      <ModalCustom
        message={resp}
        isOpen={modalOpen}
        onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
        icon={err ? <UnSuccess /> : <SavedSuccess />}
      />
    </Box>
  );
};
export default DetailProccessApprovePo;
