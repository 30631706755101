import {
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "./styles/InputDateRangePicker.css";
import DateUtil from "../../utils/dateUtil";
import { useCallback } from "react";
import useStateHistory from "../../utils/useStateHistory";
import { useEffect } from "react";
import { Calendar } from "../icons";
import ButtonPrimary from "../button/ButtonPrimary";
import ButtonCustom from "../button/ButtonCustom";
import { WHITE_COLOR } from "../../constant/propertiesConstant";
import { useTranslation } from "react-i18next";
import ButtonCalendar from "../button/ButtonCalendar";

const InputDateTimePicker = ({
  id,
  width,
  marginstart,
  marginend,
  name,
  value = null,
  onBlur,
  placeholder,
  inputFormat,
  onChange = ([startDate, endDate]) => {},
  datePickerProps,
} = {}) => {
  const [date, setDate] = useState(null);
  const [
    stateHistory,
    setStateHistory,
    { history, setHistory, index, setIndex, undo, redo, reset },
  ] = useStateHistory(value, 2);

  const initialFocusRef = useRef();
  const isDoneRef = useRef(false);
  const { t } = useTranslation();
  const onChangeDate = useCallback(
    (valueDate) => {
      setDate(valueDate);

      if (valueDate) {
        setHistory((history) => [history[0], valueDate]);
        setIndex(1);
      }
    },
    [setHistory, setIndex]
  );

  const handleOnClose = useCallback(() => {
    if (isDoneRef.current) {
      isDoneRef.current = false;
      if (onChange && typeof onChange === "function") onChange(date);
      return (() => {
        setHistory([date]);
        setIndex(0);
      })();
    }

    return undo();
  }, [undo, date, setHistory, setIndex, onChange]);

  const handleButtonToClose = useCallback((isDone = false, onClose) => {
    return () => {
      isDoneRef.current = isDone;
      if (onClose && typeof onClose === "function") onClose();
    };
  }, []);

  useEffect(() => {
    setDate(stateHistory);
  }, [stateHistory]);

  useEffect(() => {
    setHistory([value]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <Popover
        initialFocusRef={initialFocusRef}
        placement="bottom-start"
        onClose={handleOnClose}
      >
        {({ isOpen, onClose }) => (
          <>
            <PopoverTrigger>
              <InputGroup
                width={width}
                marginStart={marginstart}
                marginEnd={marginend}
              >
                <Input
                  id={id}
                  name={name}
                  value={DateUtil.formatDate(date, inputFormat )}
                  backgroundColor={WHITE_COLOR}
                  readOnly
                  cursor="pointer"
                  onBlur={onBlur}
                  placeholder={placeholder}
                />
                <InputRightElement
                  pointerEvents="none"
                  children={<Calendar />}
                />
              </InputGroup>
            </PopoverTrigger>
            <PopoverContent color={WHITE_COLOR}>
              <PopoverBody padding="0">
                <ReactDatePicker
                  id={`datepicker-${name ?? ""}`}
                  selected={date}
                  showTimeInput
                  timeFormat="hh:mm:ss"
                  onChange={onChangeDate}
                  shouldCloseOnSelect={false}
                  disabledKeyboardNavigation={true}
                  inline
                  {...datePickerProps}
                />
              </PopoverBody>
              <PopoverFooter
                border="0"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pb={4}
              >
                <Flex width="full" justifyContent="space-between">
                  <ButtonCustom
                    colorScheme="white"
                    color="black"
                    marginRight={2}
                    minWidth="100px"
                    maxWidth="100px"
                    marginLeft="2"
                    onClick={handleButtonToClose(false, onClose)}
                    text={t("COMMON:BUTTON_CANCEL")}
                  ></ButtonCustom>
                  <ButtonPrimary
                    minWidth="100px"
                    maxWidth="100px"
                    marginRight="2"
                    text={t("COMMON:BUTTON_DONE")}
                    onClick={handleButtonToClose(true, onClose)}
                  ></ButtonPrimary>
                </Flex>
              </PopoverFooter>
            </PopoverContent>
          </>
        )}
      </Popover>
    </>
  );
};

export default InputDateTimePicker;
